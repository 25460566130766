import React, { FC } from 'react';
import { graphql } from 'gatsby';

import useScreenRecognition from 'hooks/useScreenRecognition';
import parseVideo from 'components/common/Video/parsers';
import { parseNFButton } from 'components/common/NFButton/parsers';
import ControlledCarousel from 'components/ControlledCarousel';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import NFButton from 'components/common/NFButton';
import Video from '../common/Video';

import { IVideoBlocksWithCarouselSectionProps } from './model';
import responsiveObj from './constants';
import './VideoBlocksWithCarouselSection.scss';

const VideoBlocksWithCarouselSection: FC<IVideoBlocksWithCarouselSectionProps> = ({
  title,
  videoList,
  ctaButton,
  carouselControls,
}) => {
  const { isMobile } = useScreenRecognition();

  return (
    <div className="nf-video-carousel">
      {title ? <DangerouslySetInnerHtml className="nf-video-carousel__title" html={title} /> : null}
      <ControlledCarousel
        classes="nf-video-carousel-wrapper"
        responsiveObj={responsiveObj}
        bgGradientColor={null}
        carouselControls={carouselControls}
      >
        {videoList.map(({ properties }) => {
          const { videoTitle, videoDescription, videoTitleLink, ...otherProperties } = parseVideo(
            properties
          );

          return (
            <Video
              {...otherProperties}
              {...(!isMobile ? { videoTitle, videoDescription, videoTitleLink } : {})}
              key={title + videoTitle}
            />
          );
        })}
      </ControlledCarousel>
      {ctaButton ? <NFButton {...parseNFButton(ctaButton[0].properties)} /> : null}
    </div>
  );
};

export const query = graphql`
  fragment FragmentVideoBlocksWithCarouselSection on IVideoBlocksWithCarouselSection {
    videoBlocksWithCarouselSection {
      properties {
        title
        videoList {
          properties {
            ...FragmentVideoBlock
          }
        }
        ctaButton {
          properties {
            ...FragmentNFButton
          }
        }
      }
    }
  }
`;

export default VideoBlocksWithCarouselSection;
