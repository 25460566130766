import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import TagLinksList from 'components/common/TagLinksList';
import ArticlePreview from 'components/ArticlePreview';
import RecommendedProducts from 'components/RecommendedProducts';
import NFButton from 'components/common/NFButton';

import ArticleSelector from 'components/ArticleSelector';
import { IExploreComponentProps } from './model';

import 'components/Explore/Explore.scss';

const Explore: FC<IExploreComponentProps> = ({
  articleSelector,
  exploreTitle,
  exploreDescription,
  sectionNameForSubNav,
  exploreArticlesTitle,
  exploreArticlesDescription,
  exploreColor,
  exploreTagsList,
  exploreFeaturedArticles,
  featuredArticlesCTAButton,
  exploreRecommendedProducts,
  RecommendedProductsCTAButton,
  pageName,
  videoPageContainer,
}) => {
  return (
    <div className="explore-wrapper">
      <section
        className={classNames(
          'explore',
          {
            [`${exploreColor?.[0]?.properties?.colorPicker?.label}-default-bg`]:
              exploreColor && exploreColor.length > 0,
          },
          { 'explore--article-selector': Boolean(articleSelector) }
        )}
        id={sectionNameForSubNav}
      >
        {exploreTitle ? (
          <Container fluid>
            <Row>
              <Col lg={{ span: videoPageContainer ? 14 : 12 }}>
                <div
                  className={classNames('explore__wrapper', {
                    'explore__wrapper--without-color': exploreColor?.length === 0,
                  })}
                >
                  <div className="explore__content">
                    <h2 className="explore__title">{exploreTitle}</h2>
                    {exploreDescription ? (
                      <DangerouslySetInnerHtml
                        html={exploreDescription}
                        className="explore__description"
                      />
                    ) : null}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        ) : null}

        {exploreArticlesTitle || exploreArticlesDescription ? (
          <Container fluid>
            <Row>
              {exploreArticlesTitle ? (
                <h2
                  className={classNames('explore__articles-title', {
                    'explore__articles-title--secondary': exploreTitle,
                  })}
                >
                  {exploreArticlesTitle}
                </h2>
              ) : null}
              {exploreArticlesDescription ? (
                <DangerouslySetInnerHtml
                  html={exploreArticlesDescription}
                  className="explore__articles-description"
                />
              ) : null}
            </Row>
          </Container>
        ) : null}

        {exploreFeaturedArticles ? (
          <Container fluid>
            <Row className="explore__articles">
              {exploreFeaturedArticles.map((article) => (
                <ArticlePreview key={article.title} {...article} className="explore__article" />
              ))}
            </Row>

            {featuredArticlesCTAButton ? (
              <div className="explore__articles-cta">
                <NFButton {...featuredArticlesCTAButton} />
              </div>
            ) : null}
          </Container>
        ) : null}

        {exploreRecommendedProducts ? (
          <Container fluid>
            <Row>
              <Col lg={12}>
                <RecommendedProducts {...exploreRecommendedProducts} pageName={pageName} />

                {RecommendedProductsCTAButton ? (
                  <div className="explore__products-cta">
                    <NFButton {...RecommendedProductsCTAButton} />
                  </div>
                ) : null}
              </Col>
            </Row>
          </Container>
        ) : null}

        {exploreTagsList ? (
          <Container className="explore__tags" fluid>
            {exploreTagsList.map(({ tagsListTitle, tagsFormat, tagsList }) => {
              return (
                <TagLinksList
                  key={tagsFormat}
                  title={tagsListTitle}
                  tagsList={tagsList}
                  tagsFormat={tagsFormat}
                />
              );
            })}
          </Container>
        ) : null}
      </section>
      {articleSelector ? (
        <Container fluid className="article-selector-wrapper">
          <ArticleSelector
            prevTitle={articleSelector.previousTitle}
            nextTitle={articleSelector.nextTitle}
            col={[...articleSelector.nextLink, ...articleSelector.previousLink].map((item) => ({
              link: item.url,
              title: item.name,
            }))}
          />
        </Container>
      ) : null}
    </div>
  );
};

export const query = graphql`
  fragment FragmentExplore on IExplore {
    exploreTitle
    exploreDescription
    sectionNameForSubNav
    exploreColor {
      ...FragmentColorProps
    }
    articleSelector {
      properties {
        previousTitle
        previousLink {
          url
          name
        }
        nextTitle
        nextLink {
          url
          name
        }
      }
    }
    exploreFeaturedArticles {
      properties {
        ...FragmentFeaturedItemsSection
      }
    }
    exploreArticlesTitle
    exploreArticlesDescription
    exploreRecommendedProducts {
      properties {
        exploreProductsTitle
        exploreProductsList {
          properties {
            exploreProductName
            exploreProductDescription
            exploreProductColor {
              properties {
                colorPicker {
                  label
                }
              }
            }
            exploreProductImage {
              fallbackUrl
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            exploreProductImageAlt
            exploreProductSideImage {
              fallbackUrl
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            exploreProductSideImageAlt
            exploreProductLink {
              url
              name
            }
          }
        }
        exploreProductsCTA {
          properties {
            text
            ariaLabel
            link {
              url
            }
          }
        }
      }
    }
    exploreTagsList {
      properties {
        tagsListTitle
        tagsFormat
        tagsList {
          properties {
            tagItem {
              title
              relatedPage
            }
            tagOverlayImage {
              properties {
                imgAlt
                featuredImage {
                  fallbackUrl
                  gatsbyImage {
                    childImageSharp {
                      fluid(maxWidth: 400) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Explore;
