import React, { FC } from 'react';

import { formatAnchor, removeHTMLTags } from 'utils/helpers';
import bodyRenderer from 'utils/bodyRenderer';
import parseVideo from 'components/common/Video/parsers';
import ControlledCarousel from 'components/ControlledCarousel';
import VideoPreview from 'components/VideoPreview';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import NFAccordion from 'components/common/NFAccordion';
import GatsbyImage from 'components/common/GatsbyImage';
import Video from 'components/common/Video';
import Table from 'components/common/Table';
import Quote from 'components/common/Quote';
import VideoBlocksWithCarouselSection from 'components/VideoBlocksWithCarouselSection';
import { parseExploreComponent } from 'templates/HubPage/parsers';
import Explore from 'components/Explore';
import { Container, Row, Col } from 'react-bootstrap';

import { IGroupedArticleBodySectionProps } from './model';

import './GroupedArticleBodySection.scss';

const videosResponsiveObj = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
};

const Wrapper = ({ children, videoPageContainer }) => (
  <Container fluid>
    <Row>
      <Col lg={{ span: videoPageContainer ? 10 : 8, offset: videoPageContainer ? 1 : 2 }}>
        {children}
      </Col>
    </Row>
  </Container>
);

const elements: AppPageDataTypes.TBodyElements = {
  'Text Item': ({ text }) => (
    <Wrapper videoPageContainer>
      <DangerouslySetInnerHtml html={text} />
    </Wrapper>
  ),
  'Accordion Structure': ({ items }) => (
    <Wrapper videoPageContainer>
      {items.map(({ properties }) => (
        <NFAccordion selectedIndex={-1} items={[{ ...properties }]} />
      ))}
    </Wrapper>
  ),
  'Featured Items Section': ({ featuredItemsSection, ariaLabelPrev, ariaLabelNext }) => {
    const { featuredVideos } = featuredItemsSection[0].properties;

    const parsedVideos = featuredVideos.map(({ url, videoBlock }) => ({
      url,
      ...parseVideo(videoBlock),
    }));

    return (
      <Wrapper videoPageContainer>
        <div key="Featured-Items-Section" className="video-previews-carousel">
          <ControlledCarousel
            bgGradientColor="white"
            responsiveObj={videosResponsiveObj}
            carouselControls={{
              ariaLabelPrev,
              ariaLabelNext,
            }}
          >
            {parsedVideos.map(
              ({
                url,
                videoTitle,
                videoOverlappingLabel,
                btnPlay,
                previewImg,
                videoBottomOverlappingTitle,
              }) => (
                <div key={videoTitle} className="video-preview-container">
                  <VideoPreview
                    url={url}
                    videoLabel={videoOverlappingLabel}
                    playIcon={btnPlay}
                    previewImg={previewImg}
                    bottomOverlappingTitle={videoBottomOverlappingTitle}
                  />
                </div>
              )
            )}
          </ControlledCarousel>
        </div>
      </Wrapper>
    );
  },
  'Featured Image': ({ featuredImage, imgAlt }) => (
    <Wrapper videoPageContainer>
      <GatsbyImage
        className="featured-img"
        fluid={featuredImage.gatsbyImage.childImageSharp.fluid}
        alt={imgAlt}
      />
    </Wrapper>
  ),
  Table: ({ table }) => (
    <Wrapper videoPageContainer>
      <Table content={table} />
    </Wrapper>
  ),
  Quote: ({ quote, quoteBackgroundColor }) => (
    <Quote background={quoteBackgroundColor} content={quote} />
  ),
  Explore: (item) => {
    const itemParsedData = parseExploreComponent(item);

    return <Explore videoPageContainer {...itemParsedData} />;
  },
  'Video with Bottom Accordion': ({ videoBlock, videoBottomAccordion }) => (
    <Wrapper videoPageContainer>
      <div className="video-block-with-bottom-accordion">
        <Video {...parseVideo(videoBlock[0].properties)} />
        {videoBottomAccordion?.length > 0 ? (
          <NFAccordion
            selectedIndex={-1}
            items={videoBottomAccordion[0].properties.items.map(({ properties }) => ({
              ...properties,
            }))}
          />
        ) : null}
      </div>
    </Wrapper>
  ),
  'Video Blocks With Carousel Section': ({
    videoBlocksWithCarouselSection: [
      {
        properties: { title, videoList, ctaButton },
      },
    ],
    ariaLabelPrev,
    ariaLabelNext,
  }) => {
    return (
      <Wrapper videoPageContainer>
        <VideoBlocksWithCarouselSection
          key="Video Blocks With Carousel Section"
          title={title}
          videoList={videoList}
          ctaButton={ctaButton}
          carouselControls={{
            ariaLabelPrev,
            ariaLabelNext,
          }}
        />
      </Wrapper>
    );
  },
};

const GroupedArticleBodySection: FC<IGroupedArticleBodySectionProps> = ({
  title,
  groupedContent,
  pageName,
  videoPageContainer,
}) => {
  return (
    <div className="article-anchor-block" id={formatAnchor(removeHTMLTags(title))}>
      <Wrapper videoPageContainer={videoPageContainer}>
        <DangerouslySetInnerHtml html={title} />
      </Wrapper>
      {bodyRenderer(groupedContent, elements, { pageName })}
    </div>
  );
};

export default GroupedArticleBodySection;
