import React, { FC } from 'react';
import { Link } from 'gatsby';

import { LABEL_DARK_FONT_TEXT, LABEL_LIGHT_FONT_TEXT } from 'utils/constants';
import GatsbyImage from 'components/common/GatsbyImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IVideoPreviewComponentProps } from './model';

import './VideoPreview.scss';

const VideoPreview: FC<IVideoPreviewComponentProps> = ({
  url,
  videoLabel,
  playIcon,
  previewImg,
  bottomOverlappingTitle,
}) => (
  <Link to={url} className="video-preview-card">
    {previewImg?.featuredImage?.gatsbyImage?.childImageSharp ? (
      <GatsbyImage
        fluid={previewImg.featuredImage.gatsbyImage.childImageSharp.fluid}
        alt={previewImg.imgAlt}
      />
    ) : null}

    {videoLabel ? (
      <span
        className="video-overlapping-label"
        style={
          videoLabel.coloredTextColor &&
          ({
            backgroundColor: `var(--${videoLabel.coloredTextColor})`,
            color: `var(--${
              videoLabel.coloredTextColor === 'navy' ? LABEL_LIGHT_FONT_TEXT : LABEL_DARK_FONT_TEXT
            })`,
          } as React.CSSProperties)
        }
      >
        {videoLabel.coloredText}
      </span>
    ) : null}

    {bottomOverlappingTitle ? (
      <p className="video-bottom-overlapping-title">{bottomOverlappingTitle}</p>
    ) : null}

    <DangerouslySetInnerHtml className="video-play-icon" html={playIcon} />
  </Link>
);

export default VideoPreview;
